<template>
  <div class="v-stack gap-3 h-stretch">
    <div class="card light">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <label class="text-left required">Date</label>
          <InputDatePicker v-model="startDate"></InputDatePicker>
          <div class="pane-horizontal gap-3">
            <label class="text-left required">From</label>
            <label class="text-left required">To</label>
          </div>
          <div class="pane-horizontal gap-3">
            <datetime type="time" :auto="true" v-model="startDate"></datetime>
            <datetime type="time" :auto="true" v-model="endDate"></datetime>
          </div>
          <label class="text-left required">Address</label>
          <textarea v-model="address"></textarea>
          <label class="text-left required">Description</label>
          <textarea v-model="description"></textarea>
          <div class="h-stack h-end">
            <button @click="submit()" class="submit">Create</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  props: ["id"],
  data() {
    return {
      startDate: moment().toISOString(),
      endDate: moment().toISOString(),
      address: "",
      description: "",
    };
  },
  components: {
    InputDatePicker,
  },
  methods: {
    ...mapActions(["addRecordingDay"]),
    submit() {
      this.addRecordingDay({
        startDate: this.startDate,
        endDate: this.endDate,
        address: this.address,
        description: this.description,
        project: this.id,
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>